"use client";
import { MenuItem } from "@config/menu";
import Link from "next/link";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { usePathname } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import LocalizedLink from "@components/ui/link/link";

interface HeaderMenuItemProps {
  menu: MenuItem;
}

export default function HeaderMenuItem({ menu }: HeaderMenuItemProps) {
  const pathname = usePathname();
  const locale = useLocale();
  const common = useTranslations("common");

  const isActive = pathname === menu.path || pathname === `/${locale}${menu.path}`;
  const isOneOfChildActive = menu.children?.some(
    (child) => child.path === pathname || pathname === `/${locale}${child.path}`
  );
  const isChildActive = (path: string) => path === pathname || pathname === `/${locale}${path}`;

  if (!menu.children) {
    return (
      <LocalizedLink
        href={menu.path}
        className={clsx(
          isActive ? "font-bold" : "font-light",
          "inline-flex gap-0 justify-center w-full px-4 focus:outline-none text-sm"
        )}
      >
        {common(menu.label)}
      </LocalizedLink>
    );
  }

  return (
    <div className="dropdown">
      <button
        className={clsx(
          "flex items-center m-1 gap-1 justify-center w-full px-4 py-2 focus:outline-none"
        )}
      >
        <span
          className={clsx(isOneOfChildActive || isActive ? "font-bold" : "font-light", "text-sm")}
        >
          {common(menu.label)}
        </span>
        <ChevronDownIcon />
      </button>
      <ul className="dropdown-content menu bg-base-100 rounded-box z-[1] w-32 p-2 shadow">
        {menu.children.map((item) => (
          <li key={item.path}>
            <LocalizedLink
              className={clsx(isChildActive(item.path) ? "font-bold" : "font-light")}
              href={item.path}

              // href={item.path}
            >
              {common(item.label)}
            </LocalizedLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
