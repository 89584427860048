"use client";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import { MarketType, SocketBroadcastType } from "@type/types";
import { flexibleNumberFormat } from "@utils/number.transformer";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSocket } from "src/provider/socket.provider";

interface ChangeablePercentageLabelProps {
  readonly symbol: string;
  readonly percentChange?: number | string;
  readonly marketType: MarketType;
}

export default function ChangeablePercentageLabel({
  symbol,
  percentChange: _percentChange,
  marketType,
}: ChangeablePercentageLabelProps) {
  const { data: socketData } = useSocket();
  const [percentChange, setPercentChange] = useState<number>(Number(_percentChange));

  const socketUpdatedChange = Number(
    socketData?.[SocketBroadcastType.MARKET]?.[marketType]?.data?.updatedValues?.[symbol]
      ?.percentChange
  );
  useEffect(() => {
    setPercentChange(Number(_percentChange));
  }, [_percentChange]);

  useEffect(() => {
    if (!isNaN(socketUpdatedChange)) {
      setPercentChange(socketUpdatedChange);
    }
  }, [socketUpdatedChange]);

  const getColorClass = (value: number) => {
    if (value === 0) return "";
    return value > 0 ? "text-green-500" : "text-red-500";
  };

  const getIcon = (value: number) => {
    if (value === 0) return null;
    return value > 0 ? (
      <TriangleUpIcon className="text-green-500" />
    ) : (
      <TriangleDownIcon className="text-red-500" />
    );
  };

  return (
    <div className="flex flex-row items-center justify-start">
      {getIcon(percentChange)}
      <span className={clsx("text-xs", getColorClass(percentChange))}>
        {flexibleNumberFormat(percentChange, true)}%
      </span>
    </div>
  );
}
