"use client";
import { MarketType, SocketBroadcastType } from "@type/types";
import { flexibleNumberFormat, flexibleNumberFormatForForex } from "@utils/number.transformer";
import { useState, useEffect } from "react";
import { useSocket } from "src/provider/socket.provider";

interface ChangeablePriceLabelProps {
  readonly symbol: string;
  readonly price?: string | number;
  readonly marketType: MarketType;
}

export default function ChangeablePriceLabel({
  symbol,
  price,
  marketType,
}: ChangeablePriceLabelProps) {
  const { data: socketData } = useSocket();

  const [currentPrice, setCurrentPrice] = useState<number | string>(Number(price));

  const socketUpdatedPrice =
    socketData?.[SocketBroadcastType.MARKET]?.[marketType]?.data?.updatedValues?.[symbol]?.price;

  useEffect(() => {
    setCurrentPrice(Number(price));
  }, [price]);

  useEffect(() => {
    if (socketUpdatedPrice) {
      setCurrentPrice(socketUpdatedPrice);
    }
  }, [socketUpdatedPrice]);

  return (
    <p className="text-xs">{`${
      marketType !== MarketType.CRYPTO
        ? flexibleNumberFormatForForex(currentPrice)
        : flexibleNumberFormat(currentPrice)
    } ${marketType === MarketType.FOREX ? "" : "$"}`}</p>
  );
}
