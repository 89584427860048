import React from "react";
import { InteractiveTd } from "./clickable";

export interface TableColumn<T> {
  key: string;
  label: string;
  className?: string;
  render: (data: T, index?: number) => React.ReactNode;
}

export type TableDataType<T> = {
  columnData: TableColumn<T>[];
  tableData: T[];
};

export interface TableDataProps<T> {
  data: TableDataType<T>;
  language: string;
}

export default function Table<T extends Record<string, any>>({
  data,
  language,
}: TableDataProps<T>) {
  const { columnData, tableData } = data;

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr className="border-color-light">
            {columnData?.map((item) => (
              <th key={String(item.key || item.label)}>{item.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, rowIndex) => (
            <tr key={rowIndex} className="border-color-light  ">
              {columnData.map((column, colIndex) => (
                <InteractiveTd
                  key={colIndex}
                  href={`${
                    language === "en" ? "" : `/${language}`
                  }/${rowData.marketType.toLowerCase()}/${rowData.symbol
                    .split("/")
                    .join("-")
                    .toLowerCase()}`}
                >
                  {column.render(rowData, rowIndex)}
                </InteractiveTd>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
