// lib/fetchService.ts

export type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";
export type ResponseType = "json" | "text" | "blob";

interface FetchServiceOptions {
  url: string;
  method?: FetchMethod;
  headers?: Record<string, string>;
  body?: any; // For POST/PUT methods
  params?: Record<string, string | number>; // For query parameters
  responseType?: ResponseType;
  language?: string;
}

interface FetchServiceError extends Error {
  status?: number;
  statusText?: string;
}

interface ApiResponse<T> {
  status: boolean;
  message: string;
  result: T;
}

export async function fetchService<T = any>(options?: FetchServiceOptions): Promise<T> {
  const {
    method = "GET",
    headers = {},
    body,
    params,
    responseType = "json",
    url,
    language = "en",
  } = options || {};

  // Construct query parameters string
  const queryParams = params
    ? "?" +
      Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&")
    : "";

  // Build the full URL with query parameters
  const fullUrl = `${process.env.NEXT_PUBLIC_API_URL}/${url}${queryParams}`;

  // Perform the fetch request
  const response = await fetch(fullUrl, {
    method,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": language,
      //   "Cache-Control": "no-cache", // Prevents caching of the request
      //   Pragma: "no-cache",
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
    next: { revalidate: 0 },
  });

  //   console.log("response ==>", JSON.stringify(response));

  if (!response?.ok) {
    throw new Error(`Error: Status : ${response.status} - ${response.statusText}`);
    // const error: FetchServiceError = new Error(
    //   `HTTP error! Status: ${response.status} ${response.statusText}`
    // );
    // error.status = response.status;
    // error.statusText = response.statusText;
    // throw error;
  }

  const data: ApiResponse<T> = await response.json();

  // Check if the status in the response is not true
  if (!data.status) {
    throw new Error(`Error: ${data.message}`);
  }

  return data.result;

  //   // Handle different response types
  //   switch (responseType) {
  //     case "json":
  //       return response.json() as Promise<T>;
  //     case "text":
  //       return response.text() as Promise<T>;
  //     case "blob":
  //       return response.blob() as Promise<T>;
  //     default:
  //       throw new Error("Unsupported response type");
  //   }
}
