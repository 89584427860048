"use client";
import Image from "next/image";
import { v4 as uuidv4 } from "uuid";
import { footerData } from "@config/footer";
import Link from "next/link";
import clsx from "clsx";
import { SmartLogo } from "@components/ui/icons/smart.logo";
import { useLocale, useTranslations } from "next-intl";
import LocalizedLink from "@components/ui/link/link";
export default function Footer() {
  const locale = useLocale();
  const footerSection = useTranslations("footerSection");
  const common = useTranslations("common");
  return (
    <div
      className={clsx(
        "flex flex-col justify-evenly items-center p-4 bg-color-lighter gap-6",
        "md:flex-row md:gap-2 md:px-14"
      )}
    >
      <div
        className={clsx(
          "flex self-stretch flex-col items-start gap-4 px-8",
          "md:self-center md:p-0"
        )}
      >
        <SmartLogo size={40} />
        <span className="text-sm max-w-[400px] w-[90%] leading-6">
          {footerSection("footerDescription")}
        </span>
        <div className={clsx("flex flex-row gap-4 ")}>
          {footerData.socialMedia.map((item) => (
            <a
              target="_blank"
              href={item.path}
              key={item.icon}
              className="text-xxs rounded-full bg-color-light p-2"
            >
              <Image
                className="color-red-500 w-5 h-5"
                src={`/svg/social/${item.icon}.svg`}
                alt="Logo"
                width={20}
                height={20}
              />
            </a>
          ))}
        </div>
      </div>

      {/* <ThemeSwitch /> */}
      <div
        className={clsx(
          "flex flex-row self-stretch items-start justify-between px-8 ",
          "md:gap-20 md:self-start md:px-2"
        )}
      >
        {footerData.linkItems.map((item, index) => (
          <div className="flex flex-col gap-2" key={index}>
            <span className="text-xs font-bold">{common(item.title)}</span>
            <ul className="flex flex-col gap-2">
              {item.subItems.map((link) => (
                <li key={uuidv4()} className="text-xxs text-color-subtitle">
                  {item.type === "internal" ? (
                    <LocalizedLink href={link.path}>{common(link.label)}</LocalizedLink>
                  ) : (
                    <a target="_blank" href={link.path}>
                      {common(link.label)}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
