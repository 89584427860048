export const footerData = {
  socialMedia: [
    { icon: "x", path: "https://x.com/marketpeeker" },
    { icon: "telegram", path: "https://t.me/marketpeeker" },
    { icon: "youtube", path: "https://www.youtube.com/@MarketPeeker" },
    { icon: "linkedin", path: "https://www.linkedin.com/company/marketpeeker" },
  ],
  linkItems: [
    {
      title: "product",
      type: "internal",
      subItems: [
        { label: "logIn", path: "/login" },
        { label: "signUp", path: "/signup" },
      ],
    },
    {
      title: "resources",
      type: "internal",
      subItems: [
        { label: "termsOfService", path: "/terms-of-service" },
        { label: "privacyPolicy", path: "/privacy-policy" },
        { label: "faq", path: "/faq" },
      ],
    },
    {
      title: "menu",
      type: "internal",
      subItems: [
        { label: "signal", path: "/signal" },
        { label: "calculator", path: "/calculator" },
        { label: "calendar", path: "/calendar" },
        { label: "broker", path: "/broker" },
        { label: "market", path: "/market" },
      ],
    },
    {
      title: "followUs",
      type: "external",
      subItems: [
        { label: "linkedin", path: "https://www.linkedin.com/company/marketpeeker" },
        { label: "telegram", path: "https://t.me/marketpeeker" },
        { label: "x", path: "https://x.com/marketpeeker" },
        { label: "youtube", path: "https://www.youtube.com/@MarketPeeker" },
      ],
    },
  ],
};
