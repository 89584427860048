"use client";

import Link from "next/link";
import { useLocale } from "next-intl";
import { ComponentProps } from "react";

type LocalizedLinkProps = Omit<ComponentProps<typeof Link>, "href"> & {
  href: string;
};

export default function LocalizedLink({ href, ...props }: LocalizedLinkProps) {
  const locale = useLocale();

  const localizedHref = locale === "en" ? href : `/${locale}${href}`;

  return <Link href={localizedHref} {...props} />;
}
