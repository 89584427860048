import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/public/svg/icon/calendar.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/components/ui/board/ market.board.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/components/ui/board/leaning.board.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/components/ui/link/link.tsx");
