"use client";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import { CommonMarketType, SocketBroadcastType } from "@type/types";
import { flexibleNumberFormat, flexibleNumberFormatForForex } from "@utils/number.transformer";
import { setImagePath } from "@utils/transformer";
import { useSocket } from "src/provider/socket.provider";
import PairTitledBadge from "./pair.titled.badge";
import clsx from "clsx";
import { useEffect, useState } from "react";

const imageSize = 16;
interface HeaderChangeablePriceLabelProps {
  readonly item: CommonMarketType;
}

export default function HeaderChangeablePriceLabel({ item }: HeaderChangeablePriceLabelProps) {
  const { data: socketData } = useSocket();

  const [currentPrice, setCurrentPrice] = useState<number | string>(Number(item?.price));
  const [currentPercentChange, setCurrentPercentChange] = useState<number>(
    Number(item?.percentChange ?? item?.quoteMetaData?.percentChange)
  );

  useEffect(() => {
    setCurrentPrice(Number(item?.price));
  }, [item?.price]);

  useEffect(() => {
    setCurrentPercentChange(Number(item?.percentChange ?? item?.quoteMetaData?.percentChange));
  }, [item?.percentChange, item?.quoteMetaData?.percentChange]);

  useEffect(() => {
    const socketUpdatedPrice =
      socketData?.[SocketBroadcastType.MARKET]?.[item?.marketType]?.data?.updatedValues?.[
        item?.symbol
      ]?.price;
    if (socketUpdatedPrice) {
      setCurrentPrice(socketUpdatedPrice);
    }
  }, [socketData, item?.marketType, item?.symbol]);

  useEffect(() => {
    const socketUpdatedChange =
      socketData?.[SocketBroadcastType.MARKET]?.[item?.marketType]?.data?.updatedValues?.[
        item?.symbol
      ]?.percentChange;
    if (socketUpdatedChange && !isNaN(socketUpdatedChange)) {
      setCurrentPercentChange(socketUpdatedChange);
    }
  }, [socketData, item?.marketType, item?.symbol]);

  if (!item?.price || !item?.marketType) {
    return null;
  }
  return (
    <div className="flex items-center gap-2 min-w-[200px]">
      <PairTitledBadge
        baseMarketIcon={setImagePath(item?.forexMarket?.baseLogo)}
        quoteMarketIcon={setImagePath(item?.forexMarket?.quoteLogo)}
        symbol={item.symbol}
        imageSize={imageSize}
        marketType={item.marketType}
      />
      {!!Number(currentPrice) && (
        <p className="text-xxs">{flexibleNumberFormatForForex(currentPrice)}</p>
      )}
      {!!Number(currentPercentChange) && (
        <div className="flex flex-row items-end  ml-1">
          {Number(currentPercentChange) > 0 ? (
            <TriangleUpIcon className="text-green-500 w-4" />
          ) : (
            <TriangleDownIcon className="text-red-500 w-4" />
          )}
          <p
            className={clsx(
              "text-xxs",
              Number(currentPercentChange) > 0 ? "text-green-500" : "text-red-500"
            )}
          >
            {flexibleNumberFormat(currentPercentChange, false)}%
          </p>
        </div>
      )}
    </div>
  );
}
