import { MarketType, SeoConfigDataType } from "@type/types";

export const setImagePath = (path?: string): string | undefined => {
  if (!path) {
    return undefined;
  }
  return process.env.NEXT_PUBLIC_STORAGE_URL + path;
};

export const transformMetaDataImagePath = (metaData: SeoConfigDataType): SeoConfigDataType => {
  return {
    ...metaData,
    ogImage: setImagePath(metaData.ogImage),
    twitterImage: setImagePath(metaData.twitterImage),
  };
};

export const transformTimeZoneToName = (timezone?: string): string => {
  if (!timezone) {
    return "";
  }
  const splitTimezone = timezone?.split("/")[1];
  if (!splitTimezone) {
    return "";
  }
  return splitTimezone.split("_").join(" ");
};

export const getTradingViewSymbol = (symbol: string, marketType: MarketType): string => {
  if (marketType === MarketType.CRYPTO) {
    return `${symbol.toUpperCase()}USDT`;
  }

  if (marketType === MarketType.FOREX) {
    return symbol.split("/").join("").toUpperCase();
  }
  return symbol;
};

export function convertSecondsToMinutes(seconds: number): string {
  if (seconds < 0) {
    throw new Error("Seconds cannot be negative");
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes === 0) {
    return `${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`;
  }

  return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
}

export function capitalize(str: string): string {
  if (!str) {
    return ""; // Handle empty or undefined strings
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function capitalizeEach(str: string): string {
  if (!str) {
    return ""; // Handle empty or undefined strings
  }

  return str
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
}
