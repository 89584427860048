import { MarketType } from "@type/types";
import Image from "next/image";
import LocalizedLink from "../link/link";

interface SingleTitledBadgeProps {
  imageSize?: number;
  icon?: string;
  symbol?: string;
  toMarketSymbol?: string;
  marketType?: MarketType;
}

export default function SingleTitledBadge({
  imageSize = 20,
  icon,
  symbol,
  marketType,
}: SingleTitledBadgeProps) {
  if (!symbol || !icon) {
    return null;
  }

  if (marketType) {
    return (
      <LocalizedLink
        href={`/${marketType.split("_").join("-").toLowerCase()}/${symbol.toLowerCase()}`}
        className="flex items-center gap-2"
      >
        <Image
          className="rounded-full"
          src={icon}
          alt={`icon`}
          width={imageSize}
          height={imageSize}
        />
        <span className="text-xs leading-[15px]">{`${symbol}`}</span>
      </LocalizedLink>
    );
  }
  return (
    <div className="flex items-center gap-2 ">
      <Image
        className="rounded-full"
        src={icon}
        alt={`icon`}
        width={imageSize}
        height={imageSize}
      />

      <span className="text-xs leading-[15px]">{`${symbol}`}</span>
    </div>
  );
}
