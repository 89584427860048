"use client";

import { useRouter } from "next/navigation";

type InteractiveTdProps = {
  href: string;
  children: React.ReactNode;
};

export function InteractiveTd({ href, children }: InteractiveTdProps) {
  const router = useRouter();

  const handleClick = () => {
    router.push(href);
  };

  return (
    <td className="w-[40px] max-w-[40px] min-h-12 h-12 p-3 cursor-pointer" onClick={handleClick}>
      {children}
    </td>
  );
}
