//-------response types-------//

export type GeneralPageDataType = {
  metaData: SeoConfigDataType;
  headerExchangeItems: ExchangeDataType[];
  headerForexMarketItems: CommonMarketType[];
};

export type HomePageDataType = {
  metaData: SeoConfigDataType;
  tables: {
    label: string;
    data: CommonMarketType[];
  }[];
  tutorials: TutorialType[];
  calendar: CalendarDataType[];
};

export type SeoConfigDataType = {
  id: number;
  title: string;
  description: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogUrl?: string;
  ogType?: "website" | "article";
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
  canonicalUrl?: string;
  robots?: string;
  googleSiteVerification?: string;
  platformMetadata?: { [key: string]: string };
};

//------- market types -------//
export interface SymbolMetaDataType {
  title?: string;
  description?: string;
  about?: string;
}

export interface CommodityMarketType {
  name: string;
  logo?: string;
}

export interface CryptoMarketType {
  baseLogo?: string;
  quoteLogo?: string;
  quote: string;
  logo?: string;
}
export interface ForexMarketType {
  baseLogo?: string;
  quoteLogo?: string;
  quote: string;
  logo?: string;
}
export interface StockMarketType {
  name: string;
  type: string;
  currency: string;
  country: string;
  exchange: string;
  micCode: string;
  figiCode?: string;
  logo?: string;
  isEnabled: boolean;
}

export interface SymbolMarketType {
  id: number;
  rank: number;
  name: string;
  logo?: string;
  marketCap?: string;
  volume24h?: string;
  circulationSupply?: string;
  maxSupply?: string;
  totalSupply?: string;
  percentChange24h?: string;
  marketCapDominance?: string;
}

export enum MarketType {
  CRYPTO = "CRYPTO",
  FOREX = "FOREX",
  STOCK = "STOCK",
  COMMODITY = "COMMODITY",
  CRYPTO_PAIR = "CRYPTO_PAIR",
}

export enum SocketBroadcastType {
  MARKET = "MARKET",
}
export type SocketDataType = {
  [socketDataType: string]: {
    [marketType: string]: {
      data: SocketMarketPriceDataType;
    };
  };
};

export type SocketMarketPriceDataType = {
  marketType: MarketType;
  updatedValues?: {
    [symbol: string]: {
      price?: number;
      percentChange?: number;
    };
  };
};

export interface QuoteMetaDataType {
  id: number;
  rolling1dChange?: string;
  percentChange?: string;
}

export interface CommonMarketType {
  id: number;
  uniqueId: string;
  symbol: string;
  isEnabled: boolean;
  isRealtimeActivated: boolean;
  marketType: MarketType;
  price?: string;
  percentChange?: string;
  quoteMetaData?: QuoteMetaDataType;
  symbolMetaData?: SymbolMetaDataType;
  cryptoMarket?: CryptoMarketType;
  forexMarket?: ForexMarketType;
  stockMarket?: StockMarketType;
  commodityMarket?: CommodityMarketType;
  cryptoSymbol?: SymbolMarketType;
  exchange?: string;
  priority: number;
}

//------- exchange types -------//
export enum ExchangeType {
  CRYPTO = "CRYPTO",
  COMMON = "COMMON",
}

export interface ExchangeDataType {
  id: number;
  name: string;
  symbol?: string;
  type: ExchangeType;
  code?: string;
  country?: string;
  logo?: string;
  timezone?: string;
  isEnabled: boolean;
  openingHour?: string;
  closingHour?: string;
  openingAdditional?: string;
  closingAdditional?: string;
  holidays?: {
    year: number;
    dates: string[];
  }[];
  priority: number;
}

export type MarketDataType = {
  tables: {
    label: string;
    data: CommonMarketType[];
  }[];
  metaData: SeoConfigDataType;
};

export type MarketSymbolDataType = {
  data: CommonMarketType;
  metaData: SeoConfigDataType;
};

//------- calendar types -------//

export type CalendarDataType = {
  title: string;
  country: string;
  date: string;
  impact: string;
  previous: string;
  forecast: string;
  actual: string;
};

//-------response types-------//

export type MiniDashboardInfoType = {
  title: string;
  icon: string;
  priceList: DashboardInfoRowType[];
};

export type DashboardInfoRowType = {
  name: string;

  price: string;
  percentChange?: number;
  icon: string;
};

export type EconomicCalendarType = {
  title: string;
  icon: string;
  mainPagePath: string;
  items: CalendarBoardRowType[];
};

export type PriorityType = "High" | "Medium" | "Low";

export type SignalType = "buy" | "sell" | "hold";
export type CalendarBoardRowType = {
  title: string;
  time: string;
  icon: string;
  status: PriorityType;
};

//------ Market Board ------//
export type MarketBoardHeaderItemsType = {
  label: string;
  value: string;
  percentChange: number;
};

export type MarketBoardTableItemsType = {
  row: string;
  value: any;
};

export type MarketBoardType = {
  title: string;
  icon: string;
  tabs: { title: string; value: string }[];
  headerItems: MarketBoardHeaderItemsType[];
};

//------ Signal Board ------//

export type SignalBoardHeaderItemsType = {
  label: string;
  value: string;
  percentChange: number;
};

export type SignalBoardTableItemsType = {
  row: string;
  value: any;
};

export type SignalBoardType = {
  title: string;
  icon: string;
  tabs: { title: string; value: string }[];
};

//------ Learning Board ------//

export type LearningBoardType = {
  title: string;
  icon: string;
  levels: { title: string; value: string }[];
  thumbnails: { title: string; image: string }[];
};

//------  Symbol Market Data ------//

export type PricePredictionType = {
  title: string;
  icon: string;
  description: string;
  main: {
    title: string;
    price: string;
    percentChange: number;
    days: number;
  };
  subs: {
    title: string;
    items: {
      title: string;
      price: string;
    }[];
  };
};

export type OnChainAnalysisType = {
  title: string;
  icon: string;

  items: {
    label: string;
    price: string;
  }[];
};

export type CorrelationMatrixType = {
  description: string;
  title: string;
  icon: string;
  keys: string[];
  items: {
    asset: string;
    correlation: number;
    strength: number;
  }[];
};

export type UserType = {
  name: string;
  avatar: string;
};

export type CommentType = {
  user: UserType;
  date: string;
  content: string;

  replies?: CommentReplyType[];
};

export type CommentReplyType = {
  user: UserType;
  date: string;
  content: string;
};

export type SymbolMarketDataType = {
  symbol: string;
  name: string;
  price: number;
  percentChange: number;
  icon: string;
  states: {
    label: string;
    value: string;
  }[];
  keyStates: {
    label: string;
    value: string;
    time?: string;
  }[];

  technicalAnalysis: {
    indicators: {
      title: string;
      icon: string;
      details: {
        label: string;
        value: string;
      }[];
    };
    marketSentiment: {
      title: string;
      icon: string;
      details: {
        label: string;
        value: string;
      }[];
    };
    marketSummery: {
      title: string;
      icon: string;
      description?: string;
    };
  };
  calendar: EconomicCalendarType;
  pricePredictions: PricePredictionType;
  onChainAnalysis: OnChainAnalysisType;
  correlationMatrix: CorrelationMatrixType;
  signals: SignalBoardType;
  learning: LearningBoardType;
  comments?: CommentType[];
};

export interface TutorialType {
  id: number;
  uniqueId: string;
  title: string;
  description: string;
  about: string;
  headerImage: string;
  thumbnailImage: string;
  duration: number;
  author?: string;
  url: string;
  isFree: boolean;
  isEnabled: boolean;
  appearOnHomePage: boolean;
  level: string;
  category: string;
  slug: string;
  createdAt: string;
}
